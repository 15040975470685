// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lome-js": () => import("./../../../src/pages/lome.js" /* webpackChunkName: "component---src-pages-lome-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-open-positions-research-scientist-js": () => import("./../../../src/pages/open-positions/research_scientist.js" /* webpackChunkName: "component---src-pages-open-positions-research-scientist-js" */),
  "component---src-pages-open-positions-senior-product-developer-js": () => import("./../../../src/pages/open-positions/senior_product_developer.js" /* webpackChunkName: "component---src-pages-open-positions-senior-product-developer-js" */),
  "component---src-pages-partners-tre-bonder-js": () => import("./../../../src/pages/partners/tre-bonder.js" /* webpackChunkName: "component---src-pages-partners-tre-bonder-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */)
}

